/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'polaris-coreweb/exports';

import { getPaginatedTitle } from 'Helpers';

const getHelmetForSearch = (props) => {
  const {
    layoutData: {
      page: {
        title,
      },
    },
    pagination: {
      page: curPage,
    },
  } = props;

  return (
    <Helmet>
      <title>{getPaginatedTitle(title, curPage)}</title>
    </Helmet>
  );
};

getHelmetForSearch.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.shape({
      title: PropTypes.string,
    }),
  }).isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number,
  }).isRequired,
};

export default getHelmetForSearch;
