/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'polaris-coreweb/exports';

const getHelmetForSpec = (props) => {
  const {
    layoutData: {
      page: {
        title,
      },
    },
  } = props;

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:type" content="article" />
    </Helmet>
  );
};

getHelmetForSpec.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.shape({
      title: PropTypes.string,
    }),
  }).isRequired,
};

export default getHelmetForSpec;
