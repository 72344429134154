import React from 'react';
import { ReviewList } from 'Organisms';
import uniqid from 'uniqid';

export const getReviewList = (
  title,
  summary,
  overallRating,
  extraClassNames = {},
) => (
  <ReviewList
    title={title}
    overallRatingLabel=""
    overallRating={overallRating}
    showTextRating={false}
    summary={summary}
    extraClassNames={extraClassNames}
    key={uniqid()}
  />
);
