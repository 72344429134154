/* eslint-disable react/prop-types */
import React from 'react';

import { Helmet } from 'polaris-coreweb/exports';

const getHelmetForLoading = () => (
  <Helmet>
    <title>Loading&hellip;</title>
  </Helmet>
);

export default getHelmetForLoading;
