import React, { Fragment } from 'react';
import uniqid from 'uniqid';
import { HtmlSafe } from 'polaris-coreweb/exports';
import { Rating } from '@autovia-uk/polaris-components/components/molecules/Rating';

const mapRatings = ratings => ratings.map((rating, index) => {
  const {
    label, count, rating: currentRating, text,
  } = rating;

  return (
    <Fragment key={uniqid()}>
      <Rating
        label={label}
        rating={currentRating}
        extraContent={(
          <>
            <span>{count}</span>
            <p><HtmlSafe html={text} /></p>
          </>
        )}
        extraClassNames={{
          'polaris__owner-review--rating': true,
        }}
      />
    </Fragment>
  );
});

export const getRatings = ratings => (
  <div className="polaris__owner-review--ratings">
    {mapRatings(ratings)}
  </div>
);
