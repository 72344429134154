export const getFilters = () => [
  {
    label: 'All',
    id: 'ALL',
  },
  {
    label: 'News',
    id: 'NEWS',
  },
  {
    label: 'Reviews',
    id: 'REVIEWS',
  },
  {
    label: 'Features',
    id: 'FEATURES',
  },
  {
    label: 'Advice',
    id: 'TIPS',
  },
];
