/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'polaris-coreweb/exports';

const getHelmetForPage = (props) => {
  const {
    layoutData: {
      page: {
        title,
      },
    },
    metaData: {
      pageTitle: metaTitle,
    },
  } = props;

  return (
    <Helmet>
      <title>{metaTitle || title}</title>
    </Helmet>
  );
};

getHelmetForPage.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.shape({
      title: PropTypes.string,
    }),
  }).isRequired,
  metaData: PropTypes.shape({
    pageTitle: PropTypes.string,
  }).isRequired,
};

export default getHelmetForPage;
