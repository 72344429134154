/* eslint-disable react/prop-types */
import React from 'react';

import { Helmet } from 'polaris-coreweb/exports';

const getHelmetForErrorPage = () => (
  <Helmet>
    <script type="application/ld+json">
      {`{
        "name": "Error",
        "description": "There is an error with the site"
      }`}
    </script>
    <title>Error</title>
  </Helmet>
);

export default getHelmetForErrorPage;
