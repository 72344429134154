/* eslint-disable react/prop-types */
import React from 'react';

import { Helmet } from 'polaris-coreweb/exports';

const getHelmetForNotFound = () => (
  <Helmet>
    <title>Page Not Found</title>
    <script type="application/ld+json">
      {`{
          "name": "Page Not Found",
          "description": "404 page not found"
        }`}
    </script>
    <title>Page Not Found</title>
  </Helmet>
);

export default getHelmetForNotFound;
