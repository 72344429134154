import React from 'react';
import { TaxonomyFilter } from '@autovia-uk/polaris-components/components/molecules/TaxonomyFilter';

import 'Styles/components/molecules/_TaxonomyFilter.scss';

export const getTaxonomyFilter = config => (
  <TaxonomyFilter
    label={config.label || 'Show me:'}
    listModeOptions={config.listModeOptions || {
      seeMoreLabel: 'See All',
      seeLessLabel: 'See Less',
      icon: 'down',
    }}
    currentPageUrl={config.currentPageUrl || ''}
    content={config.content || []}
  />
);
