import {
  addTopLevelFieldToNavigationMenuItemsWithChildren,
} from 'Helpers';

import { getNavigationConfig } from './getNavigationConfig';

export const getHeaderConfig = (config) => {
  if (!config) {
    return {};
  }

  const navigation = getNavigationConfig(config);
  const headerNavigation = navigation && navigation.header && [...navigation.header, ...config.header.navigation];
  const navigationMenuWithExtraField = headerNavigation && addTopLevelFieldToNavigationMenuItemsWithChildren(headerNavigation);

  return {
    ...config.header, // this contain the config.json navigation that will be overwitten by graphQL
    logoImage: {
      ...config.branding.logoImage,
    },
    navigation: navigationMenuWithExtraField,
  };
};
